<!--
 * @Author       : JiangChao
 * @Date         : 2022-12-12 13:32:07
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-05 23:26:54
 * @Description  : 訂閱製
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <!-- <p style="font-size:14px;text-align:left">支付新台幣384元押金，即可立即使用</p> -->
            <div class="card">
                <van-cell
                    title="每月NT$199"
                    style="
                        font-weight: 500;
                        text-align: center;
                        font-size: 28px;
                        padding-bottom: 20px;
                    "
                >
                </van-cell>
                <van-radio-group v-model="radio" @change="onChange">
                    <van-cell-group :border="false">
                        <van-cell title="" clickable @click="radio = '1'">
                            <template #icon>
                                <van-image
                                    width="70px"
                                    height="40px"
                                    :src="visaPayIcon"
                                />
                                <van-image
                                    width="70px"
                                    height="40px"
                                    :src="mastercardPayIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="1">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <van-cell
                    title="自訂閱日起算一個月時間"
                    style="text-align: left"
                >
                </van-cell>
            </div>

            <div class="tip">
                <p>說明：</p>
                <p>
                    訂閱後請等待30秒或確認刷卡成功，再開始租借，可享訂閱期間不限時間與次數，無限制使用；綁定後每月會自動扣款續約，如不續用請取消訂閱。
                </p>
                <p>若無法成功訂閱，請來電(02)2926-6681聯繫。</p>
                <div class="login-way">
                    <div class="sms-way" @click="confirm">訂閱</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";

export default {
    name: "",
    data() {
        return {
            radio: "",
            activeIcon: require("@/assets/imgs/png/icon_agree_selected_circle.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default_circle.png"),
            iconContentYajin: require("@/assets/imgs/png/icon_content_yajin.png"),
            // applePayIcon: require("@/assets/imgs/png/apple_pay.png"),
            // jkIcon: require("@/assets/imgs/png/img_pay.png"),
            // googlePayIcon: require("@/assets/imgs/png/google_pay.png"),
            // linePayIcon: require("@/assets/imgs/png/line_pay.jpg"),
            // pxIcon: require("@/assets/imgs/png/ic_px.jpg"),
            mastercardPayIcon: require("@/assets/imgs/png/mastercard_pay.png"),
            visaPayIcon: require("@/assets/imgs/png/visa_pay.png"),
            // subscriptionIcon: require("@/assets/imgs/png/icon_subscription.png"),
            activeClauseIcon: require("@/assets/imgs/png/icon_agree_selected.png"),
            inactiveClauseIcon: require("@/assets/imgs/png/icon_login_default.png"),
            isCheck: false,
        };
    },
    methods: {
        onChange(val) {
            console.log("val: ", val);
            this.radio = val;
        },
        confirm() {
            if (!this.radio) {
                handleErr({ app: this.$parent, msg: "請選擇訂閱方式！" });
                return;
            }
            switch (this.radio) {
                case "1":
                    this.$_href("BindCreditCard");
                    break;
            }
        },
        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },
        readChange(val) {
            console.log("val: ", val);
            if (val) {
                this.isCheck = true;
            } else {
                this.isCheck = false;
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #f8f8f8;
    text-align: center;
    padding: 20px;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        position: fixed;
        bottom: 0;
        text-align: left;
        margin-bottom: 30px;
        p {
            font-size: 14px;
        }
    }
    .read-moudle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        .clause {
            font-size: 12px;
            font-family: SourceHanSansCN, SourceHanSansCN-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 12px;
            margin-left: 10px;
        }
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
}
</style>
